.App {
  display: flex;
  height: 100%;
  background-color: #efefef;
}

.SidebarContainer {
  position: fixed;
  height: 100%;
  width: 320px;
}

.Sidebar {
  height: 100%;
  box-shadow: 0 5px 10px -5px black;
  width: 320px;
  background-color: #282c34;
}

.SidebarHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.SidebarHeader > label {
  height: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  color: #ffffff;
  font-weight: 900;
  font-family: 'Lato';
  font-size: 21px;
  letter-spacing: 0.5px;
}

.SidebarHeader > img {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.SidebarHeaderDivider {
  width: 75%;
  height: 1px;
  background-color: #ff003d;
  margin: auto;
  opacity: 0.75;
}

.IconLabel {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  padding: 10px;
  border-radius: 6px;
  margin-left: 24px;
  margin-right: 24px;
  cursor: pointer;
}

.IconLabelActive {
  background-color: #ff003d;
}

.IconLabel > img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.IconLabel .materialIcon {
  margin-right: 8px;
}

.IconLabel > label {
  height: auto;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Lato';
  font-size: 18px;
  cursor: pointer;
}

.MainContentBody {
  width: calc(100% - 320px);
  background-color: #efefef;
  overflow: auto;
}

.MainContent {
  width: 100%;
  height: 100%;
}

.TableViewCustomCellValue {
  color: #2e384d;
}

.TableView .rs-table-row-header {
  border-color: #e5e5e5;
  color: #6d7186;
}

.TableView .rs-table-cell-header {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  color: #6f7281;
  text-align: start;
}

.TableView .rs-table-cell-content {
  font-size: 16px;
  text-align: start;
  margin-left: 8px;
}

.TableView .rs-table {
  border-radius: 10px;
  overflow: scroll;
}

/* .TechniciansList .rs-table-hover .rs-table-row:hover .rs-table-cell-group .rs-table-cell {
  background: #00B0FF;
} */

.TableView .rs-btn-subtle {
  color: #2e384d;
  margin-top: -8px;
}

.TableView .rs-btn {
  font-size: 16px;
}

.TableView .rs-table-scrollbar-handle {
  background-color: #bcbebe;
}

.TableView .rs-table-pagination-toolbar {
  font-size: 16px;
}

.TableView .rs-table-pagination-page-info {
  color: #3a3a3a;
}

.TableView .rs-pagination-xs > li > a {
  font-size: 16px;
  font-weight: bold;
}

.TableView .rs-icon {
  font-size: 14px;
}

.TableView .rs-table-cell-header-icon-sort {
  font-size: 16px;
}

.TableView .rs-table-body-info {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  color: #3489ec;
}

.CustomPageHeader {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 5px #aaaaaa;
  justify-content: space-between;
  padding: 20px;
}

.CustomPageHeader label {
  color: #3489ec;
  font-weight: 700;
  font-family: 'Lato';
  font-size: 16px;
  letter-spacing: 0.1px;
}

.CustomRsLoader .rs-loader-spin::before {
  border: 6px solid #ffffff;
}

.CustomRsLoader .rs-loader-spin::after {
  border-width: 4px;
  border-style: solid;
  border-color: #ff003d transparent transparent;
}

.CustomRsLoader .rs-loader-content {
  color: #3489ec;
  line-height: 3;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.logoutBtn {
  cursor: pointer;
  color: #5f5f5f;
}

.logoutBtn:hover {
  color: #df0135;
}

.rs-table-loader-icon::after {
  border-width: 3px;
  border-style: solid;
  border-color: #ff003d transparent transparent;
}

.rs-table-loader-icon::before {
  border: 3px solid rgb(229 229 229 / 80%);
}

.rs-table-loader-text {
  color: #3489ec;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ModalTitle {
  color: #3489ec !important;
  font-family: 'Lato';
  font-size: 16px;
  /* padding-bottom: 8px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #E6E7ED; */
}

.ModalColumnLabel {
  color: #5f5f5f !important;
}

.MapLocation {
  margin-left: 16px;
  color: #3489ec;
  font-weight: bold;
  cursor: pointer;
}

.RetryUpload {
  margin-left: 64px;
  font-family: 'Lato';
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  line-height: 0px;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 3px;
  background: #3489ec;
}

.LabelVal {
  font-family: 'Lato';
  width: 60%;
  color: #2e384d;
  font-size: 14px;
}

.TestDetailsDivider {
  margin-bottom: 16px;
}

.Success {
  color: rgb(25, 139, 92);
  font-weight: bold;
}

.Failed {
  color: rgb(243, 19, 19);
  font-weight: bold;
}

.Failure {
  color: rgb(243, 19, 19);
  font-weight: bold;
}

.Timeout {
  color: rgb(233, 154, 52);
  font-weight: bold;
}

.TestDetailsContent {
  margin-top: 8px;
  margin-bottom: 8px;
  font-family: 'Lato';
  font-size: 14px;
}

.TestDetailsCatogory {
  font-weight: bold;
  box-shadow: 3px;
  font-family: 'Lato';
  font-size: 14px;
  color: #2e384d;
}

.TestDetailsCatogory .rs-divider-horizontal.rs-divider-with-text {
  margin: 21px;
}

.CloseButton {
  background-color: #3489ec;
  font-family: 'Lato';
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.CustomPageSubHeader {
  display: flex;
  margin: 10px 24px;
}

.SearchBox {
  width: 60%;
  height: 36px;
}

.AddNewButton {
  right: 24px;
  height: 36px;
  position: absolute;
  font-size: 14px !important;
  background-color: #3489ec;
  color: #fff;
  font-weight: bold;
  line-height: 19px;
  color: #ffffff;
}

.SearchIcon {
  color: #3489ec;
}

.NrCellIDSearchBox {
  width: 100% !important;
}

.cancelButton {
  float: left;
  border: 1px solid #3489ec;
  background: transparent;
  font-weight: bold;
  font-size: 14px;
  color: #3489ec;
  border-radius: 3px;
  padding-left: 16px;
  padding-right: 16px;
}

.saveButton {
  float: right;
  font-size: 14px;
  font-weight: bold;
  border-radius: 3px;
  padding-left: 16px;
  padding-right: 16px;
}

.rs-checkbox label {
  font-weight: bold !important;
}

.popUpHeading {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  letter-spacing: 0.15px;
  color: #3489ec !important;
}

.nrcellFormGroup {
  margin-bottom: 5px !important;
}

.rs-input {
  color: #2e384d !important;
}

.modalBody {
  text-align: center;
  display: flex;
}

.modalSubHeading {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #2e384d;
  margin-left: 50px;
}

.rs-radio-group-inline {
  margin-left: 10px;
}

.radioOption {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #000000;
}

.downloadImage {
  margin-left: 20px;
}

.divider {
  margin-top: 30px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #c9c7c7;
  width: 100%;
}

.modalsubHeadingBlock {
  margin-left: -100px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #2e384d;
}

.DownloadModalTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #2e384d;
  margin-left: 145px;
}

.rs-modal-body {
  padding: 0;
  margin-top: 15px;
}

#map {
  position: fixed !important;
  height: 70% !important;
  width: 71% !important;
  margin-left: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid #3489ec !important;
  border-radius: 5px !important;
}

#map > * {
  position: unset;
}
.rs-panel-body {
  padding: 0 !important;
}
.rs-uploader-trigger-btn {
  width: 115px;
  height: 42px;
  background: transparent !important;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff !important;
  letter-spacing: 0.15px;
}
.rs-uploader-trigger-btn {
  color: #000000 !important;
  width: 300px !important;
  height: 200px;
}
.rs-uploader-rs-uploader-text .rs-uploader-draggable {
  margin: auto !important;
}
.rs-uploader-trigger-btn {
  padding: 40px !important;
  border: 1px solid #fff !important;
  background: #fff !important;
}
.uploaderDesc1 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #2e384d;
}
.uploaderDesc2 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #3489ec;
}
.modalBodyTools {
  text-align: center;
  display: flex;
  background: #e6e7ed;
  padding: 50px ​230px !important;
}
.header {
  background: #fafafa;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin: 3px 0;
}
.heading1 {
  width: 40%;
  display: inline-block;
}
.heading2 {
  width: 20%;
  display: inline-block;
}
.heading3 {
  width: 20%;
  display: inline-block;
}
.heading4 {
  width: 20%;
  display: inline-block;
}
.rs-uploader-draggable {
  padding: 30px 0;
  margin-left: 200px !important;
}
.rs-modal-footer {
  margin-top: 10px;
}
.upLoaderHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #3489ec !important;
}
.upLoaderSubHeading {
  margin-top: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #2e384d;
}
.tableCell {
  color: #2e384d !important;
}

.rs-picker-toggle-value {
  color: #2c2c2c !important;
  font-family: Lato !important;
  text-align: start !important;
}

.rs-picker-disabled {
  opacity: 1 !important;
}

.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle,
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-btn {
  background: #fff !important;
}

.rs-loader-backdrop {
  width: 90% !important;
  height: 90% !important;
  margin: 0px 5px !important;
  z-index: 20000 !important;
  cursor: wait !important;
}

.rs-picker-cascader-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rs-picker-cascader-menu-items {
  height: 100% !important;
  overflow: auto;
}
.rs-picker-cascader-menu-items:nth-last-child(1) {
  height: 100% !important;
}

.rs-picker-cascader-menu-column {
  overflow-y: scroll;
  height: 100% !important;
}

.rs-picker-cascader-menu-column:nth-child(3) > ul {
  width: max-content !important;
}

.rs-picker-cascader-search-panel {
  max-height: 800px;
  overflow: auto;
}
.rs-cascader-active-site {
  background-color: #deffde;
}

.rs-cascader-sites-loading {
  opacity: 0.3;
  pointer-events: none;
  cursor: wait !important;
}

.rs-table-row {
  cursor: pointer !important;
}

.rs-radio-group-picker {
  background-color: #fff;
  margin-left: 0px !important;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.rs-radio {
  border: 1px solid lightblue;
  border-radius: 5px;
}

.rs-radio-checked {
  background-color: #3498ff;
  color: #fff !important;
  border: 0px;
}

.rs-radio-checked .rs-radio-checker > label {
  color: #fff !important;
}

.rs-radio-checker > label {
  color: #2c2c2c !important;
  font-family: Lato !important;
}
